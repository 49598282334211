import React from "react";
import { MdDesignServices } from "react-icons/md";
import { FiCodesandbox } from "react-icons/fi";
import { CgWebsite } from "react-icons/cg";
import styled from "styled-components";
import Card from "./Card";
import { Slide } from "react-awesome-reveal";

const Services = () => {
  return (
    <Container id="service">
      <Slide direction="down">
        <h1>
          My <span className="green">Experience/Roles</span>
        </h1>
   
      </Slide>
      <Cards>
        <Slide direction="left">
          <Card
            Icon={MdDesignServices}
            title={"Application Support"}
            disc={`In my role as an application support specialist, I have gained extensive experience in troubleshooting complex issues and providing effective solutions to ensure maximum uptime and reliability. Through my expertise in database management, software testing, and technical support, I have consistently delivered exceptional results while maintaining a strong focus on customer satisfaction.`}
          />
        </Slide>
        <Slide direction="up">
          <Card
            Icon={FiCodesandbox}
            title={"DevOps Engineer"}
            disc={`As a DevOps engineer, I have helped teams implement continuous integration and deployment practices to streamline software development and delivery. With expertise in automation tools, cloud infrastructure, and agile methodologies, I have helped organizations achieve faster time-to-market while improving software quality and reliability.`}
          />
        </Slide>
        <Slide direction="right">
          <Card
            Icon={CgWebsite}
            title={"Software Developer"}
            disc={`Have a deep understanding of software development processes and technologies. With proficiency in multiple programming languages and frameworks, I have contributed to the development of a range of software applications and platforms across various industries.`}
          />
        </Slide>
      </Cards>
    </Container>
  );
};

export default Services;

const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  @media (max-width: 840px) {
    width: 90%;
  }

  h1 {
    padding-top: 1rem;
  }
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 4rem;
  gap: 1rem;
`;
