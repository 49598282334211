import React from "react";
import styled from "styled-components";
import { FaLinkedinIn } from "react-icons/fa";
import { Slide } from "react-awesome-reveal";

const ProfComponent = () => {
  const cvUrl = "https://raw.githubusercontent.com/Terrynbooth2001/IMG/main/Terryn's%20Resume.pdf";

  return (
    <Container id="home">
      <Slide direction="left">
        <Texts>
          <h4>
            Hello <span className="green">I'm</span>
          </h4>
          <h1 className="green">Terryn Booth</h1>
          <h3>DevOps Engineer</h3>
          <p>
            With nearly 4 years of immersive experience in the IT sector, I've journeyed through diverse roles, from infrastructure and software development to my current niche in DevOps. 
            This multifaceted exposure underscores my adaptability and depth in harnessing the best of technology to drive business solutions.
            A notable chapter in my professional narrative was collaborating with Google Cloud, a testament to my ability to synergize with industry frontrunners and craft impactful solutions. 
            While I've had the privilege of designing e-commerce software integrations with major platforms, it's my current role in DevOps that truly exemplifies my dedication. 
            Here, I not only employ a suite of advanced tools to optimize operations but also take the helm in managing operations for high-priority clients, ensuring their strategic goals are met with precision and excellence.
            As I pursue my degree in computing, my focus remains sharply attuned to the field of DevOps, pushing the boundaries of current technology. 
            While deeply committed to ongoing learning, I have a keen interest in managing and overseeing computing operations. My ultimate ambition is to be at the forefront of tech innovation, steering businesses towards success in our complex, digital world.
          </p>
          <a href={cvUrl} download>
            <button>Download CV</button>
          </a>
          <Social>
            <p>Check out my</p>
            <div className="social-icons">
              <span>
                <a href="https://www.linkedin.com/in/terryn-b-b721b5219/">
                  <FaLinkedinIn />
                </a>
              </span>
            </div>
          </Social>
        </Texts>
      </Slide>
      <Slide direction="right">
        <Profile>
          <img
            src="https://github.com/Terrynbooth2001/IMG/raw/main/IMG_7554.jpg"
            alt="profile"
          />
        </Profile>
      </Slide>
    </Container>
  );
};


export default ProfComponent;

const Container = styled.div`
  display: flex;
  gap: 2rem;
  padding-top: 3rem;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  z-index: 1;
  @media (max-width: 840px) {
    width: 90%;
  }

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;
const Texts = styled.div`
  flex: 1;
  h4 {
    padding: 1rem 0;
    font-weight: 500;
  }
  h1 {
    font-size: 2rem;
    font-family: "Secular One", sans-serif;
    letter-spacing: 2px;
  }
  h3 {
    font-weight: 500;
    font-size: 1.2rem;
    padding-bottom: 1.2rem;
    text-transform: capitalize;
  }
  p {
    font-weight: 300;
  }

  button {
    padding: 0.7rem 2rem;
    margin-top: 3rem;
    cursor: pointer;
    background-color: #01be96;
    border: none;
    color: #fff;
    font-weight: 500;
    filter: drop-shadow(0px 10px 10px #01be9551);
    :hover {
      filter: drop-shadow(0px 10px 10px #01be9570);
    }
  }
`;
const Social = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  p {
    font-size: 0.9rem;
    @media (max-width: 690px) {
      font-size: 0.7rem;
    }
  }

  .social-icons {
    display: flex;
    align-items: center;
    gap: 1rem;
    span {
      width: 2.3rem;
      height: 2rem;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
      background-color: #01be96;
      position: relative;
      transition: transform 400ms ease-in-out;
      :hover {
        transform: rotate(360deg);
      }
    }

    a {
      color: #fff;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
const Profile = styled.div`
  img {
    width: 300px; // Explicit width for larger screens
    height: auto; // Maintain aspect ratio
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;

    @media (max-width: 1024px) {
      width: 250px; // Adjust width for medium screens
    }

    @media (max-width: 768px) {
      width: 200px; // Further reduce width for small screens
    }

    @media (max-width: 480px) {
      width: 150px; // Smallest size for very small screens
    }
  }

  :hover img {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;


