import React, { useState } from 'react';
import styled from 'styled-components';

const Header = () => {
    const [bar, setBar] = useState(false);

    // Function to close the menu
    const closeMenu = () => {
        setBar(false);
    };

    return (
        <Container bar={bar}>
            <Logo>
                <h1>Portfolio</h1>
            </Logo>
            <Nav bar={bar}>
                {/* Add closeMenu to onClick */}
                <span onClick={closeMenu}><a href="#home">Home</a></span>
                <span onClick={closeMenu}><a href="#service">Proficiencies</a></span>
                <span onClick={closeMenu}><a href="#project">Employment</a></span>
                <span onClick={closeMenu}><a href="#footer">Portfolio</a></span>
            </Nav>
            <div onClick={() => setBar(!bar)} className="bars">
                <div className="bar"></div>
            </div>
        </Container>
    );
};

export default Header;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1280px;
    width: 80%;
    margin: 0 auto;
    padding: 1.5rem 0;
    position: relative;
    animation: header 500ms ease-in-out;

    @media(max-width: 840px){
        width: 90%;
    }

    @media(max-width:640px){
        .bars{
            display: ${props => props.bar ? 'none' : 'flex'};
            width: 40px;
            height: 40px;
            position: relative;
            align-items: center;
            justify-content: center;
            padding: 0.5rem;
            z-index: 100;

            .bar{
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #fff;
                transition: all 400ms ease-in-out;

                :before, :after{
                    content: "";
                    width: 100%;
                    height: 2px;
                    background-color: #fff;
                    position: absolute;
                    transition: all 400ms ease-in-out;
                }

                :before{
                    transform: translateY(10px);
                }

                :after{
                    transform: translateY(-10px);
                }
            }
        }
    }
`;

const Logo = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    h1{
        font-weight: 600;
        font-size: 1.2rem;
    }
`;

const Nav = styled.div`
    @media(max-width:640px){
        position: fixed;
        display: ${props => props.bar ? 'flex' : 'none'};
        flex-direction: column;
        background-color: #01be96;
        inset: 0;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        gap: 2rem;
        font-weight: 700;
        height: 100vh;
        transition: height 400ms ease-in-out;
        overflow: hidden;
        z-index: 100;
    }

    span {
        margin-left: 1rem;

        a {
            color: #fff;
            text-decoration: none;
            font-weight: 400;
            position: relative;

            :before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -5px;
                height: 2px;
                background-color: #fff;
                transform: scale(0);
                transform-origin: right;
                transition: transform 400ms ease-in-out;
            }

            :hover:before {
                transform: scale(1);
                transform-origin: left;
            }

            :hover {
                opacity: 0.7;
            }
        }
    }
`;
