import styled from 'styled-components';
import React, { useState } from 'react';




const Project = (props) => {
  const { img, description, isActive, handleHover } = props.item;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    handleHover(); // call the handleHover function if any
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Container
      className="project"
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave}
    >
      <img src={img} alt="project" />
      <Description className="project-description" isActive={isHovered}>
        {description}
      </Description>
    </Container>
  );
};


const Container = styled.div`
  height: 10rem;
  background-color: #1a1b1f;
  margin: 0 0.5rem;
  padding: 0.5rem;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease-in-out;

  :hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 400ms ease-in-out;
  }

  :hover img {
    transform: scale(1.1);
  }
  
  :hover .project-description {
    display: block;
  }
`;

const Description = styled.div`
  display: ${props => props.isActive ? 'block' : 'none'};
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 8px;
  top: 50%;
  left: 50%;
  word-wrap: break-word;
  width: 80%;
  height: 80%
`;


export default Project;
